$width-sidebar: 300px;
$navbar-height: 84px;




html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  //padding-top: 80px;
}

a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.btn-primary {
  color: #ffffff;
}

.custom-file-label {
  &::after {
    content: "Elegir";
  }
}

.custom-switch {
  min-height: 38px;
  .custom-control-label {
    &::before {
      height: 2rem;
    }
    // Foreground (icon)
    &::after {
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX(1.5rem);
    }
  }

  .custom-control-label {
    min-height: 38px;
    padding-top: 8px;
    margin-bottom: 0.5rem;
  }
}

.table-sm {
  font-size: 90%;
}

.modal-body {
  font-size: 0.9rem;
}

.rotate {
  transform: rotate(180deg);
}


// navbar

.navbar-brand {
  > img {
    max-height: 38px;
  }
}

.navbar-expand .navbar-toggler {
  display: block;
}

.navbar-nav {
  .nav-item {
    .image-link {
      display: block;
      width: 40px;
      float: left;
      position: relative;
      left: 0;
      top: 0;

      @include media-breakpoint-down(sm) {
        left: auto;
        right: auto;
        float: none;
      }
    }
    .primary, .secondary {
      text-align: left;
      padding-left: 50px;
    }
    .primary {
      font-weight: 700;
      font-size: 0.9rem;
      line-height: 1.1rem;
      color: $body-color;
      padding-top: 3px;
    }
    .secondary {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.1rem;
    }
  }
}


// sidebar menu

.sidebar-menu {
  position: fixed;
  left: 0px;
  top: $navbar-height;
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
  width: $width-sidebar;
  z-index: 1029;
  background-color: $light;

  transition: left .3s ease-in-out, -webkit-transform .3s ease-in-out;

  .sidebar-content {
    padding-top: 1rem;
    width: 100%;
    height: 100%;
    background-color: $light;

  }

  .nav-item {
    border-bottom: 1px solid $gray-200;
    .nav-link {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 0.9rem;
      font-weight: 500;
      color: $gray-500;

      transition: all .3s ease-in-out, -webkit-transform .3s ease-in-out;
      transition: all .3s ease-in-out, all .3s ease-in-out;
      transition: all .3s ease-in-out, all .3s ease-in-out, -webkit-transform .3s ease-in-out;

      &:hover, &:active, &:focus, &.active {
        color: $body-color;
      }

      .icon {
        width: 30px;
        padding-right: 10px;
        color: $body-color;
        text-align: center;
      }

      &[data-toggle="collapse"] {
        position: relative;
        &:after {
          display: block;
          position: absolute;
          right: 15px;
          top: 13px;

          font-family: 'FontAwesome';
          content: "\f147";
          color: $gray-300;
        }
        &.collapsed:after {
          content: "\f196";
        }
      }

    }

    > .nav > .nav-item {
      border-bottom: 0 none transparent;
      width: 100%;
      .nav-link {
        width: 100%;
        padding-left: 50px;
      }
    }
  }

  .modal-backdrop {
    opacity: 0;
    z-index: -1;
    display: none;
  }

  @include media-breakpoint-down(md) {
    left: -100%;
    visibility: hidden;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);


    &.open {
      visibility: visible;
      left: 0;

      > .nav {
        z-index: 2;
      }

      .modal-backdrop {
        display: block;
        opacity: 0.25;
      }
    }
  }
}

// main

.container-main {
  position: relative;
  padding-left: ($width-sidebar + ($grid-gutter-width / 2));
  padding-top: $navbar-height + $grid-gutter-width;

  @include media-breakpoint-down(md) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.container-index {
  padding-top: $navbar-height + $grid-gutter-width;
}

footer {
  width: 100%;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  padding-top: $grid-gutter-width;
  padding-bottom: ($grid-gutter-width / 2);
  margin-top: $grid-gutter-width;

  border-top: 1px solid $gray-200;
  background-color: lighten($light, 3%);

  font-size: 0.8rem;
  font-weight: 400;
  color: $gray-500;

  ul.list-unstyled > li {
    padding-bottom: 0.365rem;

    a {
      font-weight: 400;
      color: $gray-500;
      &:hover, &:focus, &:active {
        color: $gray-600;
      }

      .fa {
        font-size: 1.2rem;
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        -ms-transition: color 0.3s;
        -o-transition: color 0.3s;
        transition: color 0.3s;
      }
      .fa-instagram {
        color: #F81265;
        &:hover, &:focus, &:active {
          color: darken(#F81265, 20%);
        }
      }
      .fa-facebook-square {
        color: #3B579D;
        &:hover, &:focus, &:active {
          color: darken(#3B579D, 20%);
        }
      }
      .fa-linkedin-square {
        color: #1385B0;
        &:hover, &:focus, &:active {
          color: darken(#1385B0, 20%);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

// cards

.card {

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  .place, .places, .estimate, .insurance, .lang {
    font-size: 0.85rem;
    margin-bottom: 5px;
    color: $gray-600;
    
    ul {
      margin-top: -1rem;
    }

    .fa {
      width: 20px;
      text-align: center;
    }
  }

  .place {
    font-weight: 500;
  }
  .places {
  }
  .estimate {
  }
  .lang {
  }

  .days {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 1rem;
    margin-top: 5px;
    font-size: 0.8rem;
    .col {
      background-color: #fdfdfd;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 5px;
      text-align: center;
    }
    p {
      margin-bottom: 0rem;
    }
  }

  .ref {
    font-weight: 500;
  }

  .name {
    font-size: 0.9rem;
    font-weight: 700;
    color: $gray-600;
  }
  .age {
    display: block;
    clear: both;
    font-weight: 500;
  }
  .acquire {

  }
  .psicoins {
    margin-top: 0.5rem;
    font-weight: 700;
    img {
      margin-left: 0.25rem;
      height: 28px;
      vertical-align: bottom;
    }
  }

  @include media-breakpoint-down(xs) {
    .name, .acquire, .psicoins {
      text-align: center;
    }
  }

  .collapser {
    .fa {
      -webkit-transition: transform 0.3s;
      -moz-transition: transform 0.3s;
      -ms-transition: transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
    }
  }

  .collapsible {
    margin-left: - $card-spacer-x;
    margin-right: - $card-spacer-x;

    .btn-block.btn-light {
      background-color: $gray-200;
    }

    .description {
      padding: 0.8rem;
      padding-bottom: 0;
      font-size: 0.85rem;
    }
  }

}


// imagebox - overlay
.imagebox {
  position: relative;
  overflow: hidden;
  display: block;
  text-align: center;

  .overlay-image {
    display: flex;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    transform: translateX(-100%);

    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;

    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -moz-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;

    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -moz-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;

    background-color: rgba(0,0,0,0.5);

    .fa {
      font-size: 32px;
      color: #ffffff;
      opacity: 0.5;
    }
  }

  &:hover {
    .overlay-image {
      transform: translateX(0px);
    }
  }
}

// detail / account

.list-inline-badge-o {
  .list-inline-item {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid $primary;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-bottom: 0.25rem;

    color: $primary;
    font-weight: 500;
    font-size: 0.75rem;
  }
}

.account {
  font-size: 0.95rem;

  dt, dd {
    margin-bottom: 1rem;
  }

  dt, label {
    font-size: 0.85rem;
    font-weight: 700;

    &.indent {
      padding-left: 45px;
      text-indent: -20px;

      .fa {
        width: 20px;
        text-align: center;
      }

      @include media-breakpoint-down(xs) {
        padding-left: 15px;
        text-indent: 0px;
        .fa {
          width: 18px;
          text-align: left;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.25rem;
    }
  }

}

.label-file {
  font-size: 0.85rem;
  font-weight: 700;
}

// psicoins

.widthpsicoin {
  width: auto;
  height: 20px;
  vertical-align: text-top;
  padding-left: 0.25rem;
}

.packpsicoin {
  padding-top: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  strong {
    font-weight: 700;
  }
  .bg-freud,
  .bg-jung,
  .bg-maslow {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #ffffff;
  }
  .bg-freud {
    background-color: #1E3954;
  }
  .bg-jung {
    background-color: #50A592;
  }
  .bg-maslow {
    background-color: #E16E5E;
  }

  .color-jung {
    color: #50A592;
  }
  .color-maslow {
    color: #E16E5E;
  }

  .npsicoins {
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }
  .price {
    font-size: 2.1rem;
    font-weight: 500;
    .prevprice {
      font-size: 1rem;
      vertical-align: middle;
      text-decoration: line-through;
      padding-right: 0.2rem;
    }
  }
  .discount {
    font-weight: 700;
    margin-bottom: 0;
  }
  .priceu {
    font-weight: 700;
    color: $gray-600;
  }
}


// selectpicker
.btn-selectpicker {
  background-color: #ffffff;
  border-color: $gray-400;
  font-size: 0.9rem;
  font-weight: 300;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  letter-spacing: 0rem;
}

@font-face{
  font-family:"summernote";
  font-style:normal;
  font-weight:normal;
  src:url("../fonts/summernote.eot?1d9aeaaff0a8939558a45be6cd52cd4c");
  src:url("../fonts/summernote.eot?1d9aeaaff0a8939558a45be6cd52cd4c#iefix") format("embedded-opentype"),
  url("../fonts/summernote.woff?1d9aeaaff0a8939558a45be6cd52cd4c") format("woff"),
  url("../fonts/summernote.ttf?1d9aeaaff0a8939558a45be6cd52cd4c") format("truetype")
}

// datepicker
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: $primary !important;
}