:root {
  --input-padding-x: .75rem;
  --input-padding-y: .7rem;
}

//body {
//  display: -ms-flexbox;
//  display: -webkit-box;
//  display: flex;
//  -ms-flex-align: center;
//  -ms-flex-pack: center;
//  -webkit-box-align: center;
//  align-items: center;
//  -webkit-box-pack: center;
//  justify-content: center;
//}


.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  //width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1;//1.5;
  //color: #495057;
  color: $gray-500;
  border: 1px solid transparent;
  //border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  //padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-top: calc(var(--input-padding-y));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 11px;
  color: $body-color;
  margin-top: -10px;
  background-color: rgba(255,255,255,0.7);
}